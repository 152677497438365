import { default as _2022_45impact4iEJ5LjEGRMeta } from "/opt/build/repo/packages/member-webapp/pages/2022-impact.vue?macro=true";
import { default as _91id_93HCRLrFGlybMeta } from "/opt/build/repo/packages/member-webapp/pages/confirm-advisor/[id].vue?macro=true";
import { default as agreementqg9ZPgQG2EMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/agreement.vue?macro=true";
import { default as finish_45non_45domx4Lqf0oFgZMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish-non-dom.vue?macro=true";
import { default as finish4u0GOmDOOZMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish.vue?macro=true";
import { default as gift_45aid_45signheqoMWc97OMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid-sign.vue?macro=true";
import { default as gift_45aidsyMQgEvp5zMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid.vue?macro=true";
import { default as indexy21zsM3EqxMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/index.vue?macro=true";
import { default as sign17ivmKkM4dMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/sign.vue?macro=true";
import { default as transfer_45detailsRGgrLwGI0YMeta } from "/opt/build/repo/packages/member-webapp/pages/contributions/[id]/transfer-details.vue?macro=true";
import { default as assetnrPe7gwJrFMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/asset.vue?macro=true";
import { default as amountZBduateFEeMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/amount.vue?macro=true";
import { default as asset_45ownerzbwkGuxdqgMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/asset-owner.vue?macro=true";
import { default as bankVhYtjWqussMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/bank.vue?macro=true";
import { default as confirm046mfMoy1aMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/confirm.vue?macro=true";
import { default as donorKWmEBQ12PgMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/donor.vue?macro=true";
import { default as payment_45typedZ41CUzgozMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/payment-type.vue?macro=true";
import { default as taxZ4sNFCffNhMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/tax.vue?macro=true";
import { default as daf_45accountazKfifZzs2Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/daf-account.vue?macro=true";
import { default as indexdMBxBkFa9mMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/index.vue?macro=true";
import { default as asset_45ownerYpDKFsl74TMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/asset-owner.vue?macro=true";
import { default as confirmvEZMPeeVsoMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/confirm.vue?macro=true";
import { default as donorSxVSqEQwLmMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/donor.vue?macro=true";
import { default as restrictions6eUggIrhfwMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/restrictions.vue?macro=true";
import { default as security_45detailsn1FhuVsKqdMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/security-details.vue?macro=true";
import { default as taxaemALBLpBGMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/tax.vue?macro=true";
import { default as finish5ANIgw2s8PMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/get-in-touch/finish.vue?macro=true";
import { default as newb4aiGxu723Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/get-in-touch/new.vue?macro=true";
import { default as indexjmbWSBo7FJMeta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/index.vue?macro=true";
import { default as indexxKlBnawI91Meta } from "/opt/build/repo/packages/member-webapp/pages/daf-accounts/index.vue?macro=true";
import { default as indexRquAhbacONMeta } from "/opt/build/repo/packages/member-webapp/pages/error/index.vue?macro=true";
import { default as InternalServerErrorwMbiUEEkR0Meta } from "/opt/build/repo/packages/member-webapp/pages/error/InternalServerError.vue?macro=true";
import { default as index8WKegJyyGrMeta } from "/opt/build/repo/packages/member-webapp/pages/gift-aids/[id]/index.vue?macro=true";
import { default as new7gfUrLT66pMeta } from "/opt/build/repo/packages/member-webapp/pages/grant/new.vue?macro=true";
import { default as indexZtiFYZBwUGMeta } from "/opt/build/repo/packages/member-webapp/pages/grants/[id]/index.vue?macro=true";
import { default as index8kWcsKo0uyMeta } from "/opt/build/repo/packages/member-webapp/pages/index.vue?macro=true";
import { default as maintenancebhQwqiQGaXMeta } from "/opt/build/repo/packages/member-webapp/pages/maintenance.vue?macro=true";
import { default as _91charity_name_93AQ1dEmcKD4Meta } from "/opt/build/repo/packages/member-webapp/pages/my-giving/[charity_name].vue?macro=true";
import { default as how_45we_45measure_45impactKJxDK7ZiSVMeta } from "/opt/build/repo/packages/member-webapp/pages/my-giving/how-we-measure-impact.vue?macro=true";
import { default as indexbjmyHkDaqXMeta } from "/opt/build/repo/packages/member-webapp/pages/my-giving/index.vue?macro=true";
import { default as no_45advised_45dafsxnNieVOFlbMeta } from "/opt/build/repo/packages/member-webapp/pages/no-advised-dafs.vue?macro=true";
import { default as indexGdxpAPBgXlMeta } from "/opt/build/repo/packages/member-webapp/pages/our-recommendations/index.vue?macro=true";
import { default as password_45change4CoESWnAKbMeta } from "/opt/build/repo/packages/member-webapp/pages/password-change.vue?macro=true";
import { default as indexlOHRVA6LayMeta } from "/opt/build/repo/packages/member-webapp/pages/pledge/index.vue?macro=true";
import { default as newhx02RuaIrVMeta } from "/opt/build/repo/packages/member-webapp/pages/pledge/new.vue?macro=true";
import { default as indexoug8LzkzCcMeta } from "/opt/build/repo/packages/member-webapp/pages/pledges/index.vue?macro=true";
import { default as indexdkj3Xy2qbKMeta } from "/opt/build/repo/packages/member-webapp/pages/settings/index.vue?macro=true";
import { default as signinQ9RmiewYhwMeta } from "/opt/build/repo/packages/member-webapp/pages/signin.vue?macro=true";
import { default as indexXE3iq9qyBlMeta } from "/opt/build/repo/packages/member-webapp/pages/support-fp/index.vue?macro=true";
import { default as technical_45problem3l1KsOLqahMeta } from "/opt/build/repo/packages/member-webapp/pages/technical-problem.vue?macro=true";
import { default as indexRs2w5dVFN6Meta } from "/opt/build/repo/packages/member-webapp/pages/transfer-your-daf/index.vue?macro=true";
export default [
  {
    name: _2022_45impact4iEJ5LjEGRMeta?.name ?? "2022-impact",
    path: _2022_45impact4iEJ5LjEGRMeta?.path ?? "/2022-impact",
    meta: _2022_45impact4iEJ5LjEGRMeta || {},
    alias: _2022_45impact4iEJ5LjEGRMeta?.alias || [],
    redirect: _2022_45impact4iEJ5LjEGRMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/2022-impact.vue").then(m => m.default || m)
  },
  {
    name: _91id_93HCRLrFGlybMeta?.name ?? "confirm-advisor-id",
    path: _91id_93HCRLrFGlybMeta?.path ?? "/confirm-advisor/:id()",
    meta: _91id_93HCRLrFGlybMeta || {},
    alias: _91id_93HCRLrFGlybMeta?.alias || [],
    redirect: _91id_93HCRLrFGlybMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/confirm-advisor/[id].vue").then(m => m.default || m)
  },
  {
    name: agreementqg9ZPgQG2EMeta?.name ?? "contributions-id-agreement",
    path: agreementqg9ZPgQG2EMeta?.path ?? "/contributions/:id()/agreement",
    meta: agreementqg9ZPgQG2EMeta || {},
    alias: agreementqg9ZPgQG2EMeta?.alias || [],
    redirect: agreementqg9ZPgQG2EMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/agreement.vue").then(m => m.default || m)
  },
  {
    name: finish_45non_45domx4Lqf0oFgZMeta?.name ?? "contributions-id-finish-non-dom",
    path: finish_45non_45domx4Lqf0oFgZMeta?.path ?? "/contributions/:id()/finish-non-dom",
    meta: finish_45non_45domx4Lqf0oFgZMeta || {},
    alias: finish_45non_45domx4Lqf0oFgZMeta?.alias || [],
    redirect: finish_45non_45domx4Lqf0oFgZMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish-non-dom.vue").then(m => m.default || m)
  },
  {
    name: finish4u0GOmDOOZMeta?.name ?? "contributions-id-finish",
    path: finish4u0GOmDOOZMeta?.path ?? "/contributions/:id()/finish",
    meta: finish4u0GOmDOOZMeta || {},
    alias: finish4u0GOmDOOZMeta?.alias || [],
    redirect: finish4u0GOmDOOZMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/finish.vue").then(m => m.default || m)
  },
  {
    name: gift_45aid_45signheqoMWc97OMeta?.name ?? "contributions-id-gift-aid-sign",
    path: gift_45aid_45signheqoMWc97OMeta?.path ?? "/contributions/:id()/gift-aid-sign",
    meta: gift_45aid_45signheqoMWc97OMeta || {},
    alias: gift_45aid_45signheqoMWc97OMeta?.alias || [],
    redirect: gift_45aid_45signheqoMWc97OMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid-sign.vue").then(m => m.default || m)
  },
  {
    name: gift_45aidsyMQgEvp5zMeta?.name ?? "contributions-id-gift-aid",
    path: gift_45aidsyMQgEvp5zMeta?.path ?? "/contributions/:id()/gift-aid",
    meta: gift_45aidsyMQgEvp5zMeta || {},
    alias: gift_45aidsyMQgEvp5zMeta?.alias || [],
    redirect: gift_45aidsyMQgEvp5zMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/gift-aid.vue").then(m => m.default || m)
  },
  {
    name: indexy21zsM3EqxMeta?.name ?? "contributions-id",
    path: indexy21zsM3EqxMeta?.path ?? "/contributions/:id()",
    meta: indexy21zsM3EqxMeta || {},
    alias: indexy21zsM3EqxMeta?.alias || [],
    redirect: indexy21zsM3EqxMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: sign17ivmKkM4dMeta?.name ?? "contributions-id-sign",
    path: sign17ivmKkM4dMeta?.path ?? "/contributions/:id()/sign",
    meta: sign17ivmKkM4dMeta || {},
    alias: sign17ivmKkM4dMeta?.alias || [],
    redirect: sign17ivmKkM4dMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/sign.vue").then(m => m.default || m)
  },
  {
    name: transfer_45detailsRGgrLwGI0YMeta?.name ?? "contributions-id-transfer-details",
    path: transfer_45detailsRGgrLwGI0YMeta?.path ?? "/contributions/:id()/transfer-details",
    meta: transfer_45detailsRGgrLwGI0YMeta || {},
    alias: transfer_45detailsRGgrLwGI0YMeta?.alias || [],
    redirect: transfer_45detailsRGgrLwGI0YMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/contributions/[id]/transfer-details.vue").then(m => m.default || m)
  },
  {
    name: assetnrPe7gwJrFMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-asset",
    path: assetnrPe7gwJrFMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/asset",
    meta: assetnrPe7gwJrFMeta || {},
    alias: assetnrPe7gwJrFMeta?.alias || [],
    redirect: assetnrPe7gwJrFMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/asset.vue").then(m => m.default || m)
  },
  {
    name: amountZBduateFEeMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-amount",
    path: amountZBduateFEeMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/amount",
    meta: amountZBduateFEeMeta || {},
    alias: amountZBduateFEeMeta?.alias || [],
    redirect: amountZBduateFEeMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/amount.vue").then(m => m.default || m)
  },
  {
    name: asset_45ownerzbwkGuxdqgMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-asset-owner",
    path: asset_45ownerzbwkGuxdqgMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/asset-owner",
    meta: asset_45ownerzbwkGuxdqgMeta || {},
    alias: asset_45ownerzbwkGuxdqgMeta?.alias || [],
    redirect: asset_45ownerzbwkGuxdqgMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/asset-owner.vue").then(m => m.default || m)
  },
  {
    name: bankVhYtjWqussMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-bank",
    path: bankVhYtjWqussMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/bank",
    meta: bankVhYtjWqussMeta || {},
    alias: bankVhYtjWqussMeta?.alias || [],
    redirect: bankVhYtjWqussMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/bank.vue").then(m => m.default || m)
  },
  {
    name: confirm046mfMoy1aMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-confirm",
    path: confirm046mfMoy1aMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/confirm",
    meta: confirm046mfMoy1aMeta || {},
    alias: confirm046mfMoy1aMeta?.alias || [],
    redirect: confirm046mfMoy1aMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/confirm.vue").then(m => m.default || m)
  },
  {
    name: donorKWmEBQ12PgMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-donor",
    path: donorKWmEBQ12PgMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/donor",
    meta: donorKWmEBQ12PgMeta || {},
    alias: donorKWmEBQ12PgMeta?.alias || [],
    redirect: donorKWmEBQ12PgMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/donor.vue").then(m => m.default || m)
  },
  {
    name: payment_45typedZ41CUzgozMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-payment-type",
    path: payment_45typedZ41CUzgozMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/payment-type",
    meta: payment_45typedZ41CUzgozMeta || {},
    alias: payment_45typedZ41CUzgozMeta?.alias || [],
    redirect: payment_45typedZ41CUzgozMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/payment-type.vue").then(m => m.default || m)
  },
  {
    name: taxZ4sNFCffNhMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-cash-tax",
    path: taxZ4sNFCffNhMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/cash/tax",
    meta: taxZ4sNFCffNhMeta || {},
    alias: taxZ4sNFCffNhMeta?.alias || [],
    redirect: taxZ4sNFCffNhMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/cash/tax.vue").then(m => m.default || m)
  },
  {
    name: daf_45accountazKfifZzs2Meta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-daf-account",
    path: daf_45accountazKfifZzs2Meta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/daf-account",
    meta: daf_45accountazKfifZzs2Meta || {},
    alias: daf_45accountazKfifZzs2Meta?.alias || [],
    redirect: daf_45accountazKfifZzs2Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/daf-account.vue").then(m => m.default || m)
  },
  {
    name: indexdMBxBkFa9mMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution",
    path: indexdMBxBkFa9mMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution",
    meta: indexdMBxBkFa9mMeta || {},
    alias: indexdMBxBkFa9mMeta?.alias || [],
    redirect: indexdMBxBkFa9mMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/index.vue").then(m => m.default || m)
  },
  {
    name: asset_45ownerYpDKFsl74TMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-public-securities-asset-owner",
    path: asset_45ownerYpDKFsl74TMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/public-securities/asset-owner",
    meta: asset_45ownerYpDKFsl74TMeta || {},
    alias: asset_45ownerYpDKFsl74TMeta?.alias || [],
    redirect: asset_45ownerYpDKFsl74TMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/asset-owner.vue").then(m => m.default || m)
  },
  {
    name: confirmvEZMPeeVsoMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-public-securities-confirm",
    path: confirmvEZMPeeVsoMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/public-securities/confirm",
    meta: confirmvEZMPeeVsoMeta || {},
    alias: confirmvEZMPeeVsoMeta?.alias || [],
    redirect: confirmvEZMPeeVsoMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/confirm.vue").then(m => m.default || m)
  },
  {
    name: donorSxVSqEQwLmMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-public-securities-donor",
    path: donorSxVSqEQwLmMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/public-securities/donor",
    meta: donorSxVSqEQwLmMeta || {},
    alias: donorSxVSqEQwLmMeta?.alias || [],
    redirect: donorSxVSqEQwLmMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/donor.vue").then(m => m.default || m)
  },
  {
    name: restrictions6eUggIrhfwMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-public-securities-restrictions",
    path: restrictions6eUggIrhfwMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/public-securities/restrictions",
    meta: restrictions6eUggIrhfwMeta || {},
    alias: restrictions6eUggIrhfwMeta?.alias || [],
    redirect: restrictions6eUggIrhfwMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/restrictions.vue").then(m => m.default || m)
  },
  {
    name: security_45detailsn1FhuVsKqdMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-public-securities-security-details",
    path: security_45detailsn1FhuVsKqdMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/public-securities/security-details",
    meta: security_45detailsn1FhuVsKqdMeta || {},
    alias: security_45detailsn1FhuVsKqdMeta?.alias || [],
    redirect: security_45detailsn1FhuVsKqdMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/security-details.vue").then(m => m.default || m)
  },
  {
    name: taxaemALBLpBGMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-contribution-public-securities-tax",
    path: taxaemALBLpBGMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/contribution/public-securities/tax",
    meta: taxaemALBLpBGMeta || {},
    alias: taxaemALBLpBGMeta?.alias || [],
    redirect: taxaemALBLpBGMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/contribution/public-securities/tax.vue").then(m => m.default || m)
  },
  {
    name: finish5ANIgw2s8PMeta?.name ?? "daf-accounts-gift_account_id-legal_entity-get-in-touch-finish",
    path: finish5ANIgw2s8PMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/get-in-touch/finish",
    meta: finish5ANIgw2s8PMeta || {},
    alias: finish5ANIgw2s8PMeta?.alias || [],
    redirect: finish5ANIgw2s8PMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/get-in-touch/finish.vue").then(m => m.default || m)
  },
  {
    name: newb4aiGxu723Meta?.name ?? "daf-accounts-gift_account_id-legal_entity-get-in-touch-new",
    path: newb4aiGxu723Meta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()/get-in-touch/new",
    meta: newb4aiGxu723Meta || {},
    alias: newb4aiGxu723Meta?.alias || [],
    redirect: newb4aiGxu723Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/get-in-touch/new.vue").then(m => m.default || m)
  },
  {
    name: indexjmbWSBo7FJMeta?.name ?? "daf-accounts-gift_account_id-legal_entity",
    path: indexjmbWSBo7FJMeta?.path ?? "/daf-accounts/:gift_account_id()/:legal_entity()",
    meta: indexjmbWSBo7FJMeta || {},
    alias: indexjmbWSBo7FJMeta?.alias || [],
    redirect: indexjmbWSBo7FJMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/[gift_account_id]/[legal_entity]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxKlBnawI91Meta?.name ?? "daf-accounts",
    path: indexxKlBnawI91Meta?.path ?? "/daf-accounts",
    meta: indexxKlBnawI91Meta || {},
    alias: indexxKlBnawI91Meta?.alias || [],
    redirect: indexxKlBnawI91Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/daf-accounts/index.vue").then(m => m.default || m)
  },
  {
    name: indexRquAhbacONMeta?.name ?? "error",
    path: indexRquAhbacONMeta?.path ?? "/error",
    meta: indexRquAhbacONMeta || {},
    alias: indexRquAhbacONMeta?.alias || [],
    redirect: indexRquAhbacONMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: InternalServerErrorwMbiUEEkR0Meta?.name ?? "error-InternalServerError",
    path: InternalServerErrorwMbiUEEkR0Meta?.path ?? "/error/InternalServerError",
    meta: InternalServerErrorwMbiUEEkR0Meta || {},
    alias: InternalServerErrorwMbiUEEkR0Meta?.alias || [],
    redirect: InternalServerErrorwMbiUEEkR0Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/error/InternalServerError.vue").then(m => m.default || m)
  },
  {
    name: index8WKegJyyGrMeta?.name ?? "gift-aids-id",
    path: index8WKegJyyGrMeta?.path ?? "/gift-aids/:id()",
    meta: index8WKegJyyGrMeta || {},
    alias: index8WKegJyyGrMeta?.alias || [],
    redirect: index8WKegJyyGrMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/gift-aids/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: new7gfUrLT66pMeta?.name ?? "grant-new",
    path: new7gfUrLT66pMeta?.path ?? "/grant/new",
    meta: new7gfUrLT66pMeta || {},
    alias: new7gfUrLT66pMeta?.alias || [],
    redirect: new7gfUrLT66pMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/grant/new.vue").then(m => m.default || m)
  },
  {
    name: indexZtiFYZBwUGMeta?.name ?? "grants-id",
    path: indexZtiFYZBwUGMeta?.path ?? "/grants/:id()",
    meta: indexZtiFYZBwUGMeta || {},
    alias: indexZtiFYZBwUGMeta?.alias || [],
    redirect: indexZtiFYZBwUGMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/grants/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index8kWcsKo0uyMeta?.name ?? "index",
    path: index8kWcsKo0uyMeta?.path ?? "/",
    meta: index8kWcsKo0uyMeta || {},
    alias: index8kWcsKo0uyMeta?.alias || [],
    redirect: index8kWcsKo0uyMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancebhQwqiQGaXMeta?.name ?? "maintenance",
    path: maintenancebhQwqiQGaXMeta?.path ?? "/maintenance",
    meta: maintenancebhQwqiQGaXMeta || {},
    alias: maintenancebhQwqiQGaXMeta?.alias || [],
    redirect: maintenancebhQwqiQGaXMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91charity_name_93AQ1dEmcKD4Meta?.name ?? "my-giving-charity_name",
    path: _91charity_name_93AQ1dEmcKD4Meta?.path ?? "/my-giving/:charity_name()",
    meta: _91charity_name_93AQ1dEmcKD4Meta || {},
    alias: _91charity_name_93AQ1dEmcKD4Meta?.alias || [],
    redirect: _91charity_name_93AQ1dEmcKD4Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/my-giving/[charity_name].vue").then(m => m.default || m)
  },
  {
    name: how_45we_45measure_45impactKJxDK7ZiSVMeta?.name ?? "my-giving-how-we-measure-impact",
    path: how_45we_45measure_45impactKJxDK7ZiSVMeta?.path ?? "/my-giving/how-we-measure-impact",
    meta: how_45we_45measure_45impactKJxDK7ZiSVMeta || {},
    alias: how_45we_45measure_45impactKJxDK7ZiSVMeta?.alias || [],
    redirect: how_45we_45measure_45impactKJxDK7ZiSVMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/my-giving/how-we-measure-impact.vue").then(m => m.default || m)
  },
  {
    name: indexbjmyHkDaqXMeta?.name ?? "my-giving",
    path: indexbjmyHkDaqXMeta?.path ?? "/my-giving",
    meta: indexbjmyHkDaqXMeta || {},
    alias: indexbjmyHkDaqXMeta?.alias || [],
    redirect: indexbjmyHkDaqXMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/my-giving/index.vue").then(m => m.default || m)
  },
  {
    name: no_45advised_45dafsxnNieVOFlbMeta?.name ?? "no-advised-dafs",
    path: no_45advised_45dafsxnNieVOFlbMeta?.path ?? "/no-advised-dafs",
    meta: no_45advised_45dafsxnNieVOFlbMeta || {},
    alias: no_45advised_45dafsxnNieVOFlbMeta?.alias || [],
    redirect: no_45advised_45dafsxnNieVOFlbMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/no-advised-dafs.vue").then(m => m.default || m)
  },
  {
    name: indexGdxpAPBgXlMeta?.name ?? "our-recommendations",
    path: indexGdxpAPBgXlMeta?.path ?? "/our-recommendations",
    meta: indexGdxpAPBgXlMeta || {},
    alias: indexGdxpAPBgXlMeta?.alias || [],
    redirect: indexGdxpAPBgXlMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/our-recommendations/index.vue").then(m => m.default || m)
  },
  {
    name: password_45change4CoESWnAKbMeta?.name ?? "password-change",
    path: password_45change4CoESWnAKbMeta?.path ?? "/password-change",
    meta: password_45change4CoESWnAKbMeta || {},
    alias: password_45change4CoESWnAKbMeta?.alias || [],
    redirect: password_45change4CoESWnAKbMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/password-change.vue").then(m => m.default || m)
  },
  {
    name: indexlOHRVA6LayMeta?.name ?? "pledge",
    path: indexlOHRVA6LayMeta?.path ?? "/pledge",
    meta: indexlOHRVA6LayMeta || {},
    alias: indexlOHRVA6LayMeta?.alias || [],
    redirect: indexlOHRVA6LayMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/pledge/index.vue").then(m => m.default || m)
  },
  {
    name: newhx02RuaIrVMeta?.name ?? "pledge-new",
    path: newhx02RuaIrVMeta?.path ?? "/pledge/new",
    meta: newhx02RuaIrVMeta || {},
    alias: newhx02RuaIrVMeta?.alias || [],
    redirect: newhx02RuaIrVMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/pledge/new.vue").then(m => m.default || m)
  },
  {
    name: indexoug8LzkzCcMeta?.name ?? "pledges",
    path: indexoug8LzkzCcMeta?.path ?? "/pledges",
    meta: indexoug8LzkzCcMeta || {},
    alias: indexoug8LzkzCcMeta?.alias || [],
    redirect: indexoug8LzkzCcMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/pledges/index.vue").then(m => m.default || m)
  },
  {
    name: indexdkj3Xy2qbKMeta?.name ?? "settings",
    path: indexdkj3Xy2qbKMeta?.path ?? "/settings",
    meta: indexdkj3Xy2qbKMeta || {},
    alias: indexdkj3Xy2qbKMeta?.alias || [],
    redirect: indexdkj3Xy2qbKMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: signinQ9RmiewYhwMeta?.name ?? "signin",
    path: signinQ9RmiewYhwMeta?.path ?? "/signin",
    meta: signinQ9RmiewYhwMeta || {},
    alias: signinQ9RmiewYhwMeta?.alias || [],
    redirect: signinQ9RmiewYhwMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: indexXE3iq9qyBlMeta?.name ?? "support-fp",
    path: indexXE3iq9qyBlMeta?.path ?? "/support-fp",
    meta: indexXE3iq9qyBlMeta || {},
    alias: indexXE3iq9qyBlMeta?.alias || [],
    redirect: indexXE3iq9qyBlMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/support-fp/index.vue").then(m => m.default || m)
  },
  {
    name: technical_45problem3l1KsOLqahMeta?.name ?? "technical-problem",
    path: technical_45problem3l1KsOLqahMeta?.path ?? "/technical-problem",
    meta: technical_45problem3l1KsOLqahMeta || {},
    alias: technical_45problem3l1KsOLqahMeta?.alias || [],
    redirect: technical_45problem3l1KsOLqahMeta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/technical-problem.vue").then(m => m.default || m)
  },
  {
    name: indexRs2w5dVFN6Meta?.name ?? "transfer-your-daf",
    path: indexRs2w5dVFN6Meta?.path ?? "/transfer-your-daf",
    meta: indexRs2w5dVFN6Meta || {},
    alias: indexRs2w5dVFN6Meta?.alias || [],
    redirect: indexRs2w5dVFN6Meta?.redirect,
    component: () => import("/opt/build/repo/packages/member-webapp/pages/transfer-your-daf/index.vue").then(m => m.default || m)
  }
]