export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"msapplication-TileColor","content":"#ffffff"},{"name":"msapplication-TileImage","content":"/favicons/mstile-150x150.png"},{"name":"msapplication-config","content":"/favicons/browserconfig.xml"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicons/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicons/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicons/favicon-32x32.png?v=3"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicons/favicon-16x16.png?v=3"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/favicons/android-chrome-192x192.png"},{"rel":"icon","type":"image/png","sizes":"512x512","href":"/favicons/android-chrome-512x512.png"},{"rel":"mask-icon","href":"/favicons/safari-pinned-tab.svg","color":"#5bbad5"},{"rel":"manifest","href":"/favicons/site.webmanifest"}],"style":[],"script":[],"noscript":[],"title":"Founders Pledge","titleTemplate":"%s | Founders Pledge","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false